import { datadogRum } from '@datadog/browser-rum';
import { applyPolyfills } from '@market/web-components/loader';
import { defineCustomElements } from '@market/web-components/dist/custom-elements';
import * as Sentry from '@sentry/react';
import { environment } from 'utils/environment';
import wildcardPathParameters from './wildcardPaths';

export const initSentry = (savt: string) => {
  Sentry.init({
    dsn: 'https://6e6eafe489ba45899c666ddd5fb05629@o160250.ingest.sentry.io/1730086',
    environment: environment().name,
    // ignore errors caused by client stopping loading
    // https://adithya.dev/sentry-unhandled-failed-to-fetch/
    ignoreErrors: [
      'TypeError: Failed to fetch',
      'TypeError: NetworkError when attempting to fetch resource.',
      'TypeError: cancelled',
      'Object Not Found Matching Id',
    ],
  });
  // This will be overwritten by the actual user id if/when we render an AuthenticatedRoute
  Sentry.setUser({ id: savt });
};

export const initMarketReactBindings = () => {
  applyPolyfills().then(() => {
    defineCustomElements(window);
  });
};

export const initDataDogRum = (manuallyTrackPageViews: boolean) => {
  datadogRum.init({
    // found within DD at rum/list
    applicationId: '14c86734-2433-4833-bcb2-44da951d5d69',
    // found within DD at organization-settings/client-tokens
    clientToken: 'pub82c6eed1b33deb6dca085b69f961e130',
    site: 'datadoghq.com',
    service: 'square-profile',
    env: environment().name,
    trackViewsManually: manuallyTrackPageViews,
    beforeSend(event) {
      if (!manuallyTrackPageViews) {
        return true;
      }
      if (event.type !== 'view') {
        return true;
      }

      const wildcardedViewName = wildcardPathParameters(event.view.name!);
      // We may not have a view name if the user navigates to a page that doesn't match a route
      // In that case, we'll use the raw path as the view name
      event.view.name = wildcardedViewName || event.view.name;
      return true;
    },
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    defaultPrivacyLevel: 'mask',
    version: process.env.GIT_COMMIT,
    allowedTracingUrls: [
      /squareup\.buyerportal\.BuyerPortalService/,
      /squareup\.buyerportal\.orders.OrderService/,
      /squareup\.buyerportal\.merchantportal\.MerchantPortalService/,
      /squareup\.buyerportal\.merchantportal\.loyalty\.LoyaltyService/,
      /squareup\.receipts\.SubscriptionService/,
      /squareup\.multipass\.external\.MultipassExternalService/,
    ],
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
  });
};
